import { z } from "zod";

import { AgeGenderLevelModel } from "./AgeGenderLevelModel";
import { AgeGroupEnum } from "./AgeGroupEnum";
import { AdvancingRuleType, GroupKind, competitionResolver, tournamentDivisionResolver } from "./CompetitionModel";

import { MatchResultEnum } from "./CompetitionTeamsStandingModel";
import { authResolver, baseCreateType, logoResolver } from "./CreateTypes";
import { facilityModel } from "./FacilityModel";
import { locationModel } from "./LocationModel";
import { MatchStatusEnum, calendarEventObject } from "./MatchStatusEnum";
import { RefereeRoleEnum } from "./OrganizationModel";
import { statsResolver } from "./StatModel";
import { fullTeamResolver } from "./TeamModel";
import { userBaseModel } from "./UserModel";

export const matchPlayerResolver = statsResolver.extend({
	id: z.string(),
	name: z.string(),
	avatar: logoResolver.nullable(),
	jerseyNumber: z.number().optional().nullable()
});

export const groupTeamAge = z.object({
	id: z.string(),
	name: z.string().optional(),
	ord: z.number().optional(),
	date: z.string(),
	type: z.nativeEnum(AgeGroupEnum),
	value: z.number()
});

export const groupTeamModel = baseCreateType.pick({ id: true, name: true, logo: true }).extend({
	club: baseCreateType.pick({ id: true, logo: true, name: true, location: true }),
	coaches: z.array(userBaseModel).optional(),
	age: groupTeamAge,
	gender: z.object({
		id: z.string(),
		value: z.string(),
		ord: z.number()
	}),
	level: z.object({
		id: z.string(),
		name: z.string(),
		ord: z.number()
	}),
	players: z.array(matchPlayerResolver).optional(),
	score: z.number().optional()
});

const baseMatchModel = z.object({
	id: z.string(),
	date: z.string(),
	ord: z.number().optional().nullable()
});

export const createMatchModel = baseMatchModel.extend({
	homeTeam: z.object({ id: z.string() }),
	awayTeam: z.object({ id: z.string() })
});

export const leagueGroupModel = AgeGenderLevelModel.extend({
	id: z.string(),
	name: z.string(),
	kind: z.nativeEnum(GroupKind).optional().nullable(),
	competition: competitionResolver,
	division: baseCreateType.pick({ id: true, name: true, logo: true }).optional().nullable()
});

export const advancingTeamRule = z.object({
	rank: z.number(),
	visualRank: z.number().optional().nullable(),
	type: z.nativeEnum(AdvancingRuleType),
	sourceMatchId: z.string().nullable().optional(),
	sourceGroupId: z.string().nullable().optional(),
	sourceGroup: z
		.object({
			id: z.string(),
			name: z.string()
		})
		.optional()
		.nullable()
});

export type AdvancingTeamRuleModel = z.infer<typeof advancingTeamRule>;

export const matchMeta = z.object({
	round: z.number(),
	position: z.number()
});

export type MatchMetaModel = z.infer<typeof matchMeta>;

export const matchModel = baseMatchModel.extend({
	homeTeam: fullTeamResolver.optional().nullable(), // it could be null if remove team from group, but schedule was creted
	awayTeam: fullTeamResolver.optional().nullable(),
	division: tournamentDivisionResolver.pick({ id: true, name: true }).optional().nullable(),
	date: z.string().optional(),
	playedAt: z.string().optional().nullable(),
	startsAt: z.string().optional().nullable(),
	endsAt: z.string().optional().nullable(),
	facility: facilityModel.optional().nullable(),
	group: leagueGroupModel.nullable().optional(),
	crossoverPlayGroup: leagueGroupModel.nullable().optional(),
	plannedDurationMinutes: z.number().optional(),
	field: z
		.object({
			id: z.string(),
			name: z.string(),
			location: locationModel.optional().nullable(),
			facility: z
				.lazy(() => facilityModel)
				.optional()
				.nullable()
		})
		.optional()
		.nullable(),
	referees: z.array(
		z.object({
			id: z.string().optional(),
			role: z.nativeEnum(RefereeRoleEnum),
			avatar: logoResolver.nullable().optional(),
			name: z.string().nullable(),
			shortName: z.string().optional().nullable()
		})
	),
	result: z.nativeEnum(MatchResultEnum).optional().nullable(),
	status: z.nativeEnum(MatchStatusEnum).optional().nullable(),
	auth: authResolver.optional(),
	homeTeamAdvancingRule: advancingTeamRule.optional().nullable(),
	awayTeamAdvancingRule: advancingTeamRule.optional().nullable(),
	meta: matchMeta.optional().nullable(),
	type: z.nativeEnum(GroupKind).optional().nullable()
});

export const createScheduleMatchModel = z.object({
	date: z.string().nullable(),
	homeTeamId: z.string().nullable(),
	awayTeamId: z.string().nullable(),
	playedAt: z.string().nullable(),
	facilityId: z.string().nullable(),
	fieldId: z.string().nullable(),
	referees: z.array(
		z.object({
			refereeId: z.string(),
			role: z.nativeEnum(RefereeRoleEnum)
		})
	),
	homeTeamAdvancingRule: advancingTeamRule.optional().nullable(),
	awayTeamAdvancingRule: advancingTeamRule.optional().nullable(),
	meta: matchMeta.optional().nullable()
});

const baseGroupScheduleResolver = z.object({
	id: z.string(),
	daysOfWeek: z.array(z.string()),
	group: z.object({ id: z.string() }).optional(),
	matchDurationMinutes: z.number(),
	matchesPerDay: z.number(),
	rounds: z.number(),
	startsOn: z.string()
});

export const groupScheduleResolver = baseGroupScheduleResolver.extend({
	matches: z.array(matchModel)
});

export const matchTeamsResolver = z.object({
	homeTeam: groupTeamModel,
	awayTeam: groupTeamModel
});

export type GroupTeamModel = z.infer<typeof groupTeamModel>;
export type CreateMatchModel = z.infer<typeof createMatchModel>;
export type CreateScheduleMatchModel = z.infer<typeof createScheduleMatchModel>;
export type MatchModel = z.infer<typeof matchModel>;
export type GroupScheduleModel = z.infer<typeof groupScheduleResolver>;
export type LeagueGroupModel = z.infer<typeof leagueGroupModel>;
export type MatchTeamsModel = z.infer<typeof matchTeamsResolver>;
export type MatchPlayerModel = z.infer<typeof matchPlayerResolver>;
export type GroupTeamAgeModel = z.infer<typeof groupTeamAge>;

export const teamEventResolver = z
	.object({
		calendarEvent: calendarEventObject.nullable().optional(),
		match: matchModel.nullable().optional()
	})
	.nullable();

export type TeamEventModel = z.infer<typeof teamEventResolver>;

export enum RSVPStatusEnum {
	DECLINE = "NOT_GOING",
	GOING = "GOING",
	INTRESTED = "INTERESTED"
}

export const attendanceResolver = z.object({
	id: z.string(),
	entityId: z.string(),
	entityType: z.string(),
	person: z.object({
		id: z.string(),
		name: z.string(),
		shortName: z.string().optional(),
		avatar: logoResolver.nullable()
	}),
	status: z.nativeEnum(RSVPStatusEnum)
});

export type AttendanceModel = z.infer<typeof attendanceResolver>;

export const matchAttendanceObject = z.object({
	entityId: z.string(),
	counts: z.object({
		going: z.number(),
		notGoing: z.number(),
		interested: z.number()
	}),
	myStatus: z.nativeEnum(RSVPStatusEnum),
	sampleAttendees: z.array(
		z.object({
			id: z.string(),
			status: z.nativeEnum(RSVPStatusEnum),
			person: z.object({ id: z.string(), name: z.string(), avatar: logoResolver.nullable() })
		})
	)
});

export type MatchAttendanceModel = z.infer<typeof matchAttendanceObject>;

export type MatchesFilterModel = {
	status?: MatchStatusEnum[];
	matchNumberSearch?: string;
	teamSearch?: string;
	divisionIds?: string[];
	facilityIds?: string[];
	fieldIds?: string[];
};

import { useMemo } from "react";

import { signInWithRedirect } from "@aws-amplify/auth";

import { urlSafeEncode } from "@aws-amplify/core/internals/utils";

import { useInvitation, useUser } from "./index";
import useJwt from "../services/api/core/jwt";
import useLocalStorage from "../services/localStorage/localStorage";
import type { InvitationModel } from "../types";

const useAuth = ({
	invitationInfo,
	redirectUrl
}: {
	invitationInfo?: Partial<InvitationModel>;
	redirectUrl?: string;
}) => {
	const { setTempInvitation } = useInvitation();

	const { clearJwt } = useJwt();

	const { logout } = useUser();

	const localStorage = useLocalStorage();

	const methods = useMemo(() => {
		return {
			logoutPrevUser: () => {
				logout(false);
				clearJwt();
				localStorage.clear();
				invitationInfo && setTempInvitation(invitationInfo);
			},

			signIn: async (provider: "Google" | "Apple") => {
				await signInWithRedirect({
					provider,
					customState: invitationInfo
						? `invitationId=${invitationInfo.id}&invitationType=${invitationInfo.type}`
						: redirectUrl
							? `redirectUrl=${redirectUrl}`
							: undefined
				});
			},

			loginWithGoogle: async () => {
				try {
					await methods.signIn("Google");
				} catch (err) {
					if ((err as { name?: string })?.name === "UserAlreadyAuthenticatedException") {
						methods.logoutPrevUser();
						await methods.signIn("Google");
					}
				}
			},

			loginWithApple: async () => {
				try {
					await methods.signIn("Apple");
				} catch (err) {
					if ((err as { name?: string })?.name === "UserAlreadyAuthenticatedException") {
						methods.logoutPrevUser();
						await methods.signIn("Apple");
					}
				}
			},

			generateLink: (pageLink: string) => {
				if (invitationInfo) {
					return `${pageLink}?state=-${urlSafeEncode(`invitationType=${invitationInfo.type}&invitationId=${invitationInfo.id}`)}`;
				} else if (redirectUrl) {
					return `${pageLink}?redirectUrl=${redirectUrl}`;
				}
				return pageLink;
			}
		};
	}, [clearJwt, invitationInfo, localStorage, logout, redirectUrl, setTempInvitation]);

	return methods;
};

export default useAuth;

import { useMemo } from "react";

import config from "config/appConfig";

import { safelyParseJSON } from "utils/serviceUtils/helpers";

const { GLOBAL_CONSTANTS } = config;

const isSupported = (): boolean => {
	try {
		const key = "test_ls_key";
		localStorage.setItem(key, "test value");
		localStorage.removeItem(key);
		return true;
	} catch (error) {
		console.warn("Local storage not supported!");
		return false;
	}
};

const useLocalStorage = () => {
	const isLocalStorageAvailable = useMemo(() => isSupported(), []);

	return useMemo(
		() => ({
			set: (key: string, value: string): void => {
				if (!isLocalStorageAvailable) return;

				localStorage.setItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`, value);
			},
			get: <T>(key: string, defaultValue: Partial<T> | null): T | Partial<T> | string | null => {
				if (!isLocalStorageAvailable) return defaultValue;

				const data = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`);

				try {
					const parsedValue = safelyParseJSON<T>(data);
					return data ? (parsedValue !== undefined ? parsedValue : data) : defaultValue;
				} catch (error) {
					console.error(
						`Error while parsing local storage data for ${key}. Message: ${(error as Error).message}. Stack: ${
							(error as Error).stack
						}`
					);
					return data || defaultValue;
				}
			},
			setGlobal: (key: string, value: string): void => {
				if (!isLocalStorageAvailable) return;

				localStorage.setItem(key, value);
			},
			getGlobal: <T>(key: string, defaultValue: Partial<T> | null): T | Partial<T> | string | null => {
				if (!isLocalStorageAvailable) return defaultValue;

				const data = localStorage.getItem(key);

				try {
					const parsedValue = safelyParseJSON<T>(data);
					return data ? (parsedValue !== undefined ? parsedValue : data) : defaultValue;
				} catch (error) {
					console.error(
						`Error while parsing local storage data for ${key}. Message: ${(error as Error).message}. Stack: ${
							(error as Error).stack
						}`
					);
					return data || defaultValue;
				}
			},
			setWithExpiry: (key: string, value: string, ttl: number) => {
				const item = {
					value,
					expiry: new Date().getTime() + ttl
				};
				localStorage.setItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`, JSON.stringify(item));
			},
			getWithExpiry: (key: string) => {
				const data = localStorage.getItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`);
				if (!data) return null;

				try {
					const parsedValue = safelyParseJSON<{ value: string; expiry: number }>(data);
					if (data && parsedValue) {
						const isExpired = new Date().getTime() > parsedValue.expiry;
						if (isExpired) {
							localStorage.removeItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`);
							return null;
						}
						return parsedValue.value;
					}
					return null;
				} catch (error) {
					console.error(
						`Error while parsing local storage data for ${key}. Message: ${(error as Error).message}. Stack: ${
							(error as Error).stack
						}`
					);
					return null;
				}
			},
			remove: (key: string): void => {
				if (!isLocalStorageAvailable) return;

				localStorage.removeItem(`${GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX}${key}`);
			},
			clear: (): void => {
				if (!isLocalStorageAvailable) return;

				let isRemoving = 1; // We need it to prevent zustand to recreate it
				let removeCycleCount = 0;
				while (isRemoving && removeCycleCount <= 10) {
					// prevent infinity loop
					isRemoving = 0;
					for (let i = 0; i < localStorage.length; i++) {
						const key = localStorage.key(i) || "";
						if (
							key.startsWith(GLOBAL_CONSTANTS.LOCAL_STORAGE_PREFIX) ||
							key.startsWith(GLOBAL_CONSTANTS.LOCAL_STORAGE_COGNITO_PREFIX)
						) {
							localStorage.removeItem(key);
							isRemoving++;
						}
					}
					removeCycleCount++;
				}
			}
		}),
		[isLocalStorageAvailable]
	);
};

export default useLocalStorage;

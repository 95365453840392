import { useMemo } from "react";

import { pick } from "ramda";

import { API_URLS } from "shared/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";

import {
	competitionClubsTeamsResolver,
	competitionCreatedFieldReservationResolver,
	competitionFieldWithReservationResolver,
	competitionGroupResolver,
	competitionRemovedFieldReservationResolver,
	competitionWithScoutsResolver,
	followedCompetitionsResolver,
	getCompetitionPlayersByTeamsResolver,
	getCompetitionPlayersResolver,
	getCompetitionTeamResolver,
	statItemResolver,
	teamFiltersResolverWithId,
	tournamentDivisionResolver
} from "shared/types";
import type {
	BaseCreateType,
	ClubModel,
	CompetitionClubsTeamsModel,
	CompetitionCreateDivisionModel,
	CompetitionCreatedFieldReservationModel,
	CompetitionFieldWithReservationModel,
	CompetitionGroupModel,
	CompetitionModel,
	CompetitionRemovedFieldReservationModel,
	FetchCompetitionTeamsParams,
	FullTeamModel,
	GetCompetitionPlayersByTeamsModel,
	GetCompetitionPlayersModel,
	GetCompetitionTeamModel,
	GroupStatusEnum,
	InvitationModel,
	InvitationStatus,
	LogoModel,
	MatchModel,
	MatchesFilterModel,
	MediaModel,
	NewFieldReservationModel,
	NewScoutModel,
	OrderStatus,
	PaginationParamsModel,
	PaginationTokenType,
	RefereeManagerModel,
	ScoutModel,
	StatItemModel,
	TeamFiltersModelWithId,
	TournamentDivisionModel,
	UpdateFieldReservationModel
} from "shared/types";

import BaseConverter from "./converters/baseConverter";

import type { FieldInfo } from "../types/FacilityModel";
import type { UpdateScoutModel } from "../types/ScoutModel";

const useCompetitionApiService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			findCompetitions: async ({
				clubId,
				search,
				maxMiles,
				limit
			}: {
				clubId?: string;
				search?: string;
				maxMiles?: number;
				limit?: number;
			}) => {
				return BaseConverter.convert<{ competitions: CompetitionModel[] }>(
					await actions.get(API_URLS.COMPETITION.FIND, {
						"as-club-id": clubId,
						search,
						"max-miles": maxMiles,
						limit
					})
				);
			},
			applyToCompetition: async ({
				clubId,
				competitionId,
				teams
			}: {
				clubId: string;
				competitionId: string;
				teams?: Partial<FullTeamModel>[];
			}) => {
				return BaseConverter.convert<{ club: { id: string }; competition: { id: string } }>(
					await actions.post(API_URLS.COMPETITION.APPLY, {
						asClubId: clubId,
						competitionId,
						teams: teams?.length ? teams : []
					})
				);
			},
			getTeams: async (params: FetchCompetitionTeamsParams) => {
				const requestParams: {
					competitionId: string;
					clubId?: string;
					status?: InvitationStatus;
					ungrouped?: boolean;
					ages?: string | number | null;
					genders?: string;
					levels?: string | number;
					search?: string;
					requestGroup?: boolean;
				} = pick(["competitionId", "clubId", "status", "ungrouped", "search", "requestGroup"], params);

				if (params?.ages?.length) requestParams["ages"] = params.ages.join(",");
				if (params?.genders?.length) requestParams["genders"] = params.genders.join(",");
				if (params?.levels?.length) requestParams["levels"] = params.levels.join(",");

				return BaseConverter.convert<{ competition: GetCompetitionTeamModel }>(
					await actions.get(API_URLS.COMPETITION.TEAMS, params, {
						resolvers: [{ resolver: getCompetitionTeamResolver, fields: ["competition"] }]
					})
				);
			},
			getClubsWithTeams: async (params: FetchCompetitionTeamsParams & { withPayments?: boolean }) => {
				const requestParams: {
					competitionId: string;
					clubId?: string;
					status?: InvitationStatus;
					ungrouped?: boolean;
					ages?: string | number | null;
					genders?: string;
					levels?: string | number;
					search?: string;
					withPayments?: boolean;
				} = pick(["competitionId", "clubId", "status", "ungrouped", "withPayments", "search"], params);

				if (params?.ages?.length) requestParams["ages"] = params.ages.join(",");
				if (params?.genders?.length) requestParams["genders"] = params.genders.join(",");
				if (params?.levels?.length) requestParams["levels"] = params.levels.join(",");

				return BaseConverter.convert<{ competition: CompetitionClubsTeamsModel }>(
					await actions.get(API_URLS.COMPETITION.LIST_TEAMS_BY_CLUBS, requestParams, {
						resolvers: [{ resolver: competitionClubsTeamsResolver, fields: ["competition"] }]
					})
				);
			},
			getClubs: async ({ competitionId, status }: { competitionId: string; status: InvitationStatus }) => {
				return BaseConverter.convert<{ clubs: Pick<ClubModel, "id" | "name" | "status">[] }>(
					await actions.get(API_URLS.COMPETITION.CLUBS, {
						"competition-id": competitionId,
						status
					})
				);
			},
			getCompetitionMatches: async ({
				competitionId,
				filters,
				limit,
				after
			}: {
				competitionId: string;
				filters?: MatchesFilterModel;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					matches: MatchModel[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(API_URLS.COMPETITION.COMPETITION_MATCHES, {
						competitionId,
						status: !!filters?.status?.length ? filters.status.join(",") : undefined,
						divisionIds: !!filters?.divisionIds?.length ? filters.divisionIds.join(",") : undefined,
						facilityIds: !!filters?.facilityIds?.length ? filters.facilityIds.join(",") : undefined,
						fieldIds: !!filters?.fieldIds?.length ? filters.fieldIds.join(",") : undefined,
						ord: filters?.matchNumberSearch,
						search: filters?.teamSearch,
						limit,
						after
					})
				);
			},
			getCompetitionReferees: async (competitionId: string) => {
				return BaseConverter.convert<{ refereeOrganizations: RefereeManagerModel[] }>(
					await actions.get(API_URLS.COMPETITION.COMPETITION_REFEREES, {
						"competition-id": competitionId
					})
				);
			},
			getOverview: async (competitionId: string) => {
				return BaseConverter.convert<{ competition: CompetitionModel }>(
					await actions.get(API_URLS.COMPETITION.GET_OVERVIEW, {
						"competition-id": competitionId
					})
				);
			},
			getInfo: async (competitionId: string) => {
				return BaseConverter.convert<{ competition: CompetitionModel }>(
					await actions.get(API_URLS.COMPETITION.GET_INFO, {
						"competition-id": competitionId
					})
				);
			},
			getTeamFilters: async (competitionId: string) => {
				return BaseConverter.convert<{ competition: TeamFiltersModelWithId }>(
					await actions.get(
						API_URLS.COMPETITION.GET_TEAM_FILTERS,
						{
							"competition-id": competitionId
						},
						{ resolvers: [{ resolver: teamFiltersResolverWithId, fields: ["competition"] }] }
					)
				);
			},
			updateCompetitionSettings: async (
				updateData: Omit<CompetitionModel, "id" | "league" | "type" | "logo"> & {
					logo?: string | null | LogoModel | MediaModel;
					competitionId: string;
					refereeOrganizations: {
						add: { id: string }[];
						remove: { id: string }[];
					};
				}
			) => {
				return BaseConverter.convert(await actions.post(API_URLS.COMPETITION.UPDATE_SETTINGS, updateData));
			},
			listCompetitionFieldReservations: async (competitionId: string) => {
				return BaseConverter.convert<{ fields: CompetitionFieldWithReservationModel[] }>(
					await actions.get(
						API_URLS.COMPETITION.LIST_FIELD_RESERVATIONS,
						{
							competitionId
						},
						{
							resolvers: [
								{
									resolver: competitionFieldWithReservationResolver,
									fields: ["fields"]
								}
							]
						}
					)
				);
			},
			updateCompetitionFieldReservations: async (
				competitionId: string,
				reservations: {
					create: NewFieldReservationModel[];
					update: UpdateFieldReservationModel[];
					remove: { id: string }[];
				}
			) => {
				return BaseConverter.convert<{
					created: CompetitionCreatedFieldReservationModel[];
					removed: CompetitionRemovedFieldReservationModel[];
					updated: CompetitionRemovedFieldReservationModel[];
				}>(
					await actions.post(
						API_URLS.COMPETITION.UPDATE_FIELD_RESERVATIONS,
						{
							competitionId,
							reservations
						},
						{
							resolvers: [
								{
									resolver: competitionCreatedFieldReservationResolver,
									fields: ["created"]
								},
								{
									resolver: competitionRemovedFieldReservationResolver,
									fields: ["removed"]
								},
								{
									resolver: competitionRemovedFieldReservationResolver,
									fields: ["updated"]
								}
							]
						}
					)
				);
			},
			listCompetitionFacilityFields: async (competitionId: string) => {
				return BaseConverter.convert<{ fields: Partial<FieldInfo>[] }>(
					await actions.get(API_URLS.COMPETITION.LIST_COMPETITION_FACILITY_FIELDS, {
						competitionId
					})
				);
			},
			getCompetitionTeamStats: async (competitionId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.COMPETITION.GET_COMPETITION_TEAM_STATS,
						{
							"competition-id": competitionId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getCompetitionPlayerStats: async (competitionId: string) => {
				return BaseConverter.convert<{ items: StatItemModel[] }>(
					await actions.get(
						API_URLS.COMPETITION.GET_COMPETITION_PLAYER_STATS,
						{
							"competition-id": competitionId
						},
						{
							resolvers: [
								{
									resolver: statItemResolver,
									fields: ["items"]
								}
							]
						}
					)
				);
			},
			getCompetitionGroups: async (data: { competitionId: string; status?: GroupStatusEnum; withTeams?: boolean }) => {
				return BaseConverter.convert<{ groups: CompetitionGroupModel[] }>(
					await actions.get(
						API_URLS.COMPETITION.GET_COMPETITION_GROUPS,
						{
							"competition-id": data.competitionId,
							status: data.status,
							"with-teams": data.withTeams
						},
						{
							resolvers: [
								{
									resolver: competitionGroupResolver,
									fields: ["groups"]
								}
							]
						}
					)
				);
			},
			getFollowedCompetitions: async ({
				search,
				after
			}: {
				search?: string;
			} & PaginationParamsModel) => {
				return BaseConverter.convert<{
					competitions: BaseCreateType[];
					nextToken: PaginationTokenType;
					previousToken: PaginationTokenType;
				}>(
					await actions.get(
						API_URLS.COMPETITION.LIST_FOLLOWED_COMPETITIONS,
						{
							search: search || undefined,
							after: after || undefined
						},
						{
							resolvers: [
								{
									resolver: followedCompetitionsResolver
								}
							]
						}
					)
				);
			},
			getStructure: async (id: string) => {
				return BaseConverter.convert<{ competition: Partial<CompetitionModel> }>(
					await actions.get(API_URLS.COMPETITION.GET_STRUCTURE, {
						competitionId: id
					})
				);
			},
			getCompetitionDivisions: async (competitionId: string) => {
				return BaseConverter.convert<{ divisions: TournamentDivisionModel[] }>(
					await actions.get(
						API_URLS.COMPETITION.LIST_COMPETITION_DIVISIONS,
						{
							competitionId
						},
						{
							resolvers: [
								{
									resolver: tournamentDivisionResolver,
									fields: ["divisions"]
								}
							]
						}
					)
				);
			},
			getCompetitionDivision: async (divisionId: string) => {
				return BaseConverter.convert<{ division: TournamentDivisionModel }>(
					await actions.get(
						API_URLS.COMPETITION.GET_COMPETITION_DIVISION,
						{
							divisionId
						},
						{
							resolvers: [
								{
									resolver: tournamentDivisionResolver,
									fields: ["division"]
								}
							]
						}
					)
				);
			},
			createDivisions: async (competitionId: string, divisions: CompetitionCreateDivisionModel[]) => {
				return BaseConverter.convert<{ divisions: TournamentDivisionModel[] }>(
					await actions.post(
						API_URLS.COMPETITION.CREATE_COMPETITION_DIVISIONS,
						{
							competitionId,
							divisions
						},
						{
							resolvers: [
								{
									resolver: tournamentDivisionResolver,
									fields: ["divisions"]
								}
							]
						}
					)
				);
			},
			updateDivision: async (competitionId: string, division: CompetitionCreateDivisionModel) => {
				return BaseConverter.convert<{ division: TournamentDivisionModel }>(
					await actions.post(
						API_URLS.COMPETITION.UPDATE_COMPETITION_DIVISION,
						{
							competitionId,
							division
						},
						{
							resolvers: [
								{
									resolver: tournamentDivisionResolver,
									fields: ["division"]
								}
							]
						}
					)
				);
			},
			deleteDivision: async (competitionId: string, divisionId: string) => {
				return BaseConverter.convert<{ division: { id: string } }>(
					await actions.post(API_URLS.COMPETITION.DELETE_COMPETITION_DIVISION, {
						competitionId,
						divisionId
					})
				);
			},
			getFavoriteCompetitionDivisions: async () => {
				return BaseConverter.convert<{
					competition: Partial<CompetitionModel>;
					competitions: Partial<CompetitionModel>[];
					divisions: Partial<TournamentDivisionModel>[];
					groups: Partial<CompetitionGroupModel>[];
				}>(await actions.get(API_URLS.COMPETITION.LIST_FAVORITE_COMPETITION_DIVISIONS));
			},
			listScouts: async (competitionId: string) => {
				return BaseConverter.convert<{ competition: { id: string; scouts: ScoutModel[] } }>(
					await actions.get(
						API_URLS.COMPETITION.LIST_SCOUTS,
						{
							competitionId
						},
						{
							resolvers: [
								{
									resolver: competitionWithScoutsResolver,
									fields: ["competition"]
								}
							]
						}
					)
				);
			},
			updateScouts: async (
				competitionId: string,
				scouts: {
					add?: NewScoutModel[];
					remove?: { id: string }[];
					update?: UpdateScoutModel[];
				}
			) => {
				return BaseConverter.convert<{ competition: { id: string } }>(
					await actions.post(API_URLS.COMPETITION.UPDATE_SCOUTS, {
						competitionId,
						scouts
					})
				);
			},
			inviteScouts: async (competitionId: string, emails: string[]) => {
				return BaseConverter.convert<{ invitations: InvitationModel[] }>(
					await actions.post(API_URLS.COMPETITION.INVITE_SCOUTS, {
						competitionId,
						emails
					})
				);
			},
			updateTeams: async (
				competitionId: string,
				teams: {
					remove: { id: string }[];
				}
			) => {
				return BaseConverter.convert<{ competition: { id: string } }>(
					await actions.post(API_URLS.COMPETITION.UPDATE_TEAMS, {
						competitionId,
						teams
					})
				);
			},
			getPlayers: async (competitionId: string) => {
				return BaseConverter.convert<{ competition: GetCompetitionPlayersModel }>(
					await actions.get(
						API_URLS.COMPETITION.PLAYERS,
						{
							competitionId
						},
						{
							resolvers: [{ resolver: getCompetitionPlayersResolver, fields: ["competition"] }]
						}
					)
				);
			},
			getPlayersByTeams: async (competitionId: string) => {
				return BaseConverter.convert<{
					competition: GetCompetitionPlayersByTeamsModel;
				}>(
					await actions.get(
						API_URLS.COMPETITION.PLAYERS_BY_TEAMS,
						{
							competitionId
						},
						{
							resolvers: [{ resolver: getCompetitionPlayersByTeamsResolver, fields: ["competition"] }]
						}
					)
				);
			},
			deleteCompetition: async (competitionId: string) => {
				return BaseConverter.convert<{ competition: { id: string } }>(
					await actions.post(API_URLS.COMPETITION.DELETE, {
						competitionId
					})
				);
			},
			generateOpenRegistrationPaymentOrder: async (params: {
				competitionId: string;
				players?: { id: string }[];
				teams?: { id: string; clubId: string }[];
			}) => {
				return BaseConverter.convert<{ order: { id: string; status: OrderStatus } }>(
					await actions.post(API_URLS.COMPETITION.GENERATE_OPEN_REGISTRATION_PAYMENT_ORDER, params)
				);
			},
			getAudiences: async (competitionId: string) => {
				return BaseConverter.convert<{
					competition: { id: string; audiences: { players: { id: string }[]; teams: { id: string }[] } };
				}>(
					await actions.get(API_URLS.COMPETITION.GET_AUDIENCES, {
						competitionId
					})
				);
			},
			appendToOpenRegistration: async (params: {
				competitionId: string;
				players?: { id: string }[];
				teams?: { id: string; clubId: string }[];
			}) => {
				return BaseConverter.convert<{ competition: { id: string } }>(
					await actions.post(API_URLS.COMPETITION.APPEND_TO_COMPETITION_OPEN_REGISTRATION, params)
				);
			}
		}),
		[actions]
	);
};

export default useCompetitionApiService;

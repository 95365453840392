import { isPossiblePhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";

import { pick } from "ramda";

import type {
	BaseCreateType,
	CreateCompetitionObject,
	GenderEnum,
	LocationModel,
	LogoModel,
	MediaModel,
	SetupEntityModel
} from "shared/types";
import { AgeGroupEnum, CompetitionAdvancingStyles } from "shared/types";

import { convertDateToDateFormat } from "utils/formatRelativeDate";

import { getYearDateByAgeValue } from "utils/getAge";
import { getDateTime } from "utils/getDateTime";

export const convertCreateBaseEntityQuery = (
	data: Omit<BaseCreateType, "id" | "logo"> & { logo?: string | null | LogoModel }
): {
	name: string | null;
	location: Pick<LocationModel, "address" | "lat" | "lng"> | null;
	about?: string | null;
	phone?: string | null;
	website?: string | null;
	logo?: string | null;
} => {
	const { name, location, about, website } = data;
	let logo = (data?.logo as MediaModel)?.path || (data?.logo as string) || null;
	if (Array.isArray(data?.logo)) {
		logo = "";
	}
	let phoneNumber: string | undefined = data?.phone || "";
	if (data?.phone) {
		let correctPhone = phoneNumber.replace(/[^0-9+]/g, "").trim();
		correctPhone = phoneNumber.startsWith("+") ? correctPhone : `+${correctPhone}`;
		const parsedPhone = parsePhoneNumberFromString(correctPhone);
		if (isPossiblePhoneNumber(correctPhone) || parsedPhone?.isValid() || parsedPhone?.isPossible()) {
			phoneNumber = parsePhoneNumberFromString(data.phone, parsedPhone?.country || "US")?.format("E.164");
		}
	}
	return {
		name: name || null,
		location: location || null,
		about: about || null,
		website: website || null,
		phone: phoneNumber || null,
		logo
	};
};

export const convertUpdateBaseEntityQuery = (
	data: Omit<BaseCreateType, "id" | "logo"> & { logo?: string | null | LogoModel | MediaModel }
): {
	name?: string | null;
	location?: Pick<LocationModel, "address" | "lat" | "lng"> | null;
	about?: string | null;
	phone?: string | null;
	website?: string | null;
	logo?: string | null;
} => {
	const { name, location, about, website } = data;
	let logo: string | null | undefined = (data?.logo as MediaModel)?.path || (data?.logo as string);
	if (Array.isArray(data?.logo)) {
		logo = undefined;
	}
	let phoneNumber: string | null | undefined = data?.phone;
	if (phoneNumber) {
		let correctPhone = phoneNumber.replace(/[^0-9+]/g, "").trim();
		correctPhone = phoneNumber.startsWith("+") ? correctPhone : `+${correctPhone}`;
		const parsedPhone = parsePhoneNumberFromString(correctPhone);
		if (isPossiblePhoneNumber(correctPhone) || parsedPhone?.isValid() || parsedPhone?.isPossible()) {
			phoneNumber = parsePhoneNumberFromString(data.phone!, parsedPhone?.country || "US")?.format("E.164");
		}
	}

	return {
		name,
		location: location ? pick(["address", "lat", "lng"], location) : undefined,
		about,
		website,
		phone: phoneNumber,
		logo
	};
};

export const convertSetupEntityQuery = (
	data: SetupEntityModel & { id: string }
): {
	levels: string[];
	ages: { type: AgeGroupEnum; day?: number; month?: number; value?: number; name: string }[];
	genders: GenderEnum[];
} => ({
	levels: data.levels.map(x => x.name),
	ages: data.categories.map(x => {
		const selectedDate = getDateTime(x.date);

		const customYear = selectedDate.year;
		let year = customYear;
		if (x?.value) {
			const yearDate = getYearDateByAgeValue(x.value);
			year = yearDate.year;
		}

		if (!x.value) {
			throw new Error("Age value not provided");
		}

		return {
			type: x.behavior || AgeGroupEnum.UNDER,
			day: selectedDate.day,
			month: selectedDate.month,
			yearOffset: customYear !== year ? customYear - year : undefined,
			value: x.value!,
			name: x.name
		};
	}),
	genders: data.genders
});

export const convertCreateSeasonQuery = (data: CreateCompetitionObject) => {
	let logo: string | null | undefined = (data?.logo as MediaModel)?.path;

	if (Array.isArray(data?.logo)) {
		logo = undefined;
	}

	const latLng = data.location ? data.location.value.split("lng") : undefined;

	const location =
		data.location && latLng
			? {
					address: data.location.label,
					lat: Number(latLng[0]),
					lng: Number(latLng[1])
				}
			: undefined;

	return {
		...pick(
			[
				"leagueId",
				"type",
				"name",
				"description",
				"website",
				"rules",
				"private",
				"logo",
				"registrationType",
				"openRegistrationType",
				"playerTag"
			],
			data
		),
		logo,
		startsOn: convertDateToDateFormat(data.startsOn),
		endsOn: convertDateToDateFormat(data.endsOn),
		registrationEndsOn: convertDateToDateFormat(data.registrationEndsOn),
		paymentEndsOn: convertDateToDateFormat(data.paymentEndsOn),
		location,
		playerClubId: data?.club?.id,
		registrationFee:
			data?.registrationFee && !isNaN(data?.registrationFee) ? Number(data.registrationFee) * 100 : undefined, // convert to cents
		partialPayment: Array.isArray(data?.partialPayment)
			? data.partialPayment.map(x => ({
					frequency: x.frequency,
					numberOfInstallments: Number(x.numberOfInstallments),
					upfrontAmount: Number(x.upfrontAmount) * 100 // convert to cents
				}))
			: []
	};
};

export const convertSetupCompetitionQuery = (data: Partial<CreateCompetitionObject>) => ({
	competitionId: data.competitionId,
	winPoints: data!.winPoints,
	drawPoints: data!.drawPoints,
	lossPoints: data!.lossPoints,
	advancingStyle: CompetitionAdvancingStyles.WORLD_CUP, // TODO: integrate it into UI
	tieBreakerRules: data?.tieBreakerRules
		? data.tieBreakerRules.map((type, i) => ({
				type,
				ord: i + 1
			}))
		: [],
	levels: data.levels!.map(x => ({
		name: x.name,
		description: x?.description || ""
	})),
	ages: data.ages!.map(x => {
		const selectedDate = getDateTime(x.date);

		const customYear = selectedDate.year;
		let year = customYear;
		if (x?.value) {
			const yearDate = getYearDateByAgeValue(x.value);
			year = yearDate.year;
		}

		return {
			type: x.behavior || AgeGroupEnum.UNDER,
			day: selectedDate.day,
			month: selectedDate.month,
			yearOffset: customYear !== year ? customYear - year : undefined,
			value: x.value!,
			name: x.name,
			playersPerMatch: x.matchFormat,
			matchDuration: Number(x.duration),
			halfTime: x.includeHalftime,
			matchBuffer: Number(x.matchBuffer),
			refereesPerMatch: Number(x.refereePerGame)
		};
	}),
	genders: data.genders
});

import { AppConfig } from "config";

export const API_URLS = {
	USER: {
		CURRENT_USER_INFO: "/core/whoami",
		GET_INFO: "/soccer/get-person-info",
		GET_OVERVIEW: "/soccer/get-person-overview",
		UPDATE_PROFILE: "/core/update-my-profile",
		UPDATE_PERSON_PROFILE: "/core/update-person-profile",
		CONNECT: "/connections/establish",
		REJECT_CONNECTION: "/connections/reject",
		CANCEL_CONNECTION: "/connections/remove",
		SEARCH_CONNECTIONS: "/connections/find",
		GET_KIDS: "/core/list-my-children",
		LIST_FOLLOWED_USERS: "/core/list-followed-people",
		CHECK_EMAIL_AVAILABILITY: "/auth/check-email-availability",
		UNSUBSCRIBE_FROM_EMAILS: "/core/unsubscribe",
		FIND_USERS: "/soccer/find-people",
		ADD_EMAIL_ADDRESS: "/auth/add-email-address",
		LIST_MY_EMAILS: "/core/list-my-emails",
		CONFIRM_EMAIL_ADDRESS: "/auth/confirm-email-address",
		UPDATE_MY_ATTENDANCE: "/core/update-my-attendance",
		DELETE_ACCOUNT: "/core/delete-account",
		GET_PERSON_EXPERIENCE: "core/get-person-experience",
		UPDATE_MY_EXPERIENCE: "core/update-my-experience",
		UPDATE_PERSON_EXPERIENCE: "core/update-person-experience",
		GET_PERSON_EDUCATION: "core/get-person-education",
		UPDATE_MY_EDUCATION: "core/update-my-education",
		UPDATE_PERSON_EDUCATION: "core/update-person-education",
		GET_PERSON_SOCIAL_LINKED_ACCOUNTS: "core/get-person-social-linked-accounts",
		UPDATE_MY_SOCIAL_LINKED_ACCOUNTS: "core/update-my-social-linked-accounts",
		UPDATE_PERSON_SOCIAL_LINKED_ACCOUNTS: "core/update-person-social-linked-accounts",
		LIST_MY_PENDING_ORDERS: "/soccer/list-my-pending-orders",
		LIST_MY_TRANSACTIONS: "/soccer/list-my-transactions"
	},
	ADMIN: {
		LIST_USERS: "/admin/list-users",
		LIST_REPORTED_USERS: "/admin/list-reported-users",
		LIST_REPORTED_POSTS: "/admin/list-reported-posts",
		ASSIGN_USER_ROLES: "/admin/setup-user-role",
		BAN_USERS: "/admin/ban-users",
		IGNORE_REPORTED_USERS: "/admin/ignore-reported-users",
		IGNORE_REPORTED_POSTS: "/admin/ignore-reported-posts",
		DELETE_REPORTED_POSTS: "/admin/delete-reported-posts",
		LIST_CLUBS: "/admin/list-clubs",
		MANAGE_CLUB: "/admin/manage-club",
		LIST_LEAGUES: "/admin/list-leagues",
		MANAGE_LEAGUE: "/admin/manage-league",
		LIST_FACILITIES: "/admin/list-facilities",
		MANAGE_FACILITY: "/admin/manage-facility",
		LIST_REF_ORGS: "/admin/list-referee-organizations",
		MANAGE_REF_ORG: "/admin/manage-referee-organization"
	},
	GOOGLE_MAPS: {
		FIND_PLACE: (input: string, key: string) =>
			`https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${input}&inputtype=textquery&fields=photos,formatted_address,name,rating,opening_hours,geometry&key=${key}`
	},
	INTERESTS: {
		FOLLOW: (entityType: string) => `/soccer/follow-${entityType}`,
		UNFOLLOW: (entityType: string) => `/soccer/unfollow-${entityType}`
	},
	LEAGUE: {
		GET: "/soccer/list-leagues",
		FIND: "/soccer/find-leagues",
		GET_MINE: "/soccer/list-my-leagues",
		GET_OVERVIEW: "/soccer/get-league-overview",
		GET_INFO: "/soccer/get-league-info",
		GET_STRUCTURE: "/soccer/get-league-structure",
		GET_COMPETITIONS: "/soccer/list-league-competitions",
		CREATE: "/soccer/create-league",
		SETUP: "/soccer/setup-league",
		UPDATE: "/soccer/update-league",
		CREATE_COMPETITION: "/soccer/create-competition",
		SETUP_COMPETITION: "/soccer/setup-competition",
		GET_TEAM_STATS: "/soccer/get-league-team-stats",
		GET_PLAYER_STATS: "/soccer/get-league-player-stats",
		LIST_FOLLOWED_LEAGUES: "/soccer/list-followed-leagues"
	},
	ENTITY_MANAGEMENT: {
		LIST_MEMBERS: (entity: string) => `/soccer/list-${entity}-members`
	},
	CLUB: {
		GET: "/soccer/find-clubs",
		GET_MINE: "/soccer/list-my-clubs",
		GET_OVERVIEW: "/soccer/get-club-overview",
		GET_INFO: "/soccer/get-club-info",
		GET_STRUCTURE: "/soccer/get-club-structure",
		GET_TEAMS: "/soccer/list-club-teams",
		GET_COMPETITIONS: "/soccer/list-club-competitions",
		GET_PLAYERS: "/soccer/list-club-players",
		GET_MATCHES: "/soccer/list-club-matches",
		GET_PLAYER_TAGS: "/soccer/list-club-player-tags",
		CREATE: "/soccer/create-club",
		SETUP: "/soccer/setup-club",
		CREATE_TEAMS: "/soccer/create-teams",
		DELETE_TEAMS: "/soccer/delete-teams",
		GET_TEAM_FILTERS: "/soccer/get-club-team-filters",
		UPDATE: "/soccer/update-club",
		CREATE_PLAYERS: "/soccer/create-players",
		ADD_PLAYERS_TO_CLUB_BY_COMPETITION: "/soccer/add-players-to-club-by-competition",
		UPDATE_PLAYERS: "/soccer/update-club-players",
		GET_COMPETITION_STATS: "soccer/get-club-competition-stats",
		GET_TEAM_COMPETITION_STATS: "soccer/get-club-team-competition-stats",
		GET_PLAYER_COMPETITION_STATS: "/soccer/get-club-player-competition-stats",
		UPDATE_CLUB_COACHES: "/soccer/update-club-coaches",
		UPLOAD_CLUB_PLAYERS: "/soccer/upload-players",
		UPDATE_CLUB_TEAMS: "/soccer/update-club-teams",
		LIST_FOLLOWED_CLUBS: "/soccer/list-followed-clubs",
		RESEND_PLAYERS_INVITATION: "/soccer/resend-players-invitation"
	},
	ORGANIZATION: {
		GET: "/soccer/list-referee-organizations",
		GET_MINE: "/soccer/list-my-referee-organizations",
		CREATE: "/soccer/create-referee-organization",
		GET_OVERVIEW: "/soccer/get-reforg-overview",
		GET_INFO: "/soccer/get-reforg-info",
		UPDATE: "/soccer/update-referee-organization",
		FIND_REFEREES: "/soccer/find-referees",
		FIND_REFEREE_ORGS: "/soccer/find-referee-organization-to-competition",
		LIST_REFEREE_ORG_REFEREES: "/soccer/list-referee-organization-referees",
		FIND_REFEREE_ORGS_FOR_SEARCH: "/soccer/find-referee-organizations",
		GET_MATCHES: "/soccer/list-referee-organization-matches",
		UPDATE_REFEREES: "/soccer/update-referee-organization-referees"
	},
	COACH: {
		CREATE: "/soccer/create-coaches",
		INVITE: "/soccer/create-coaches",
		LIST_CLUB_COACHES: "/soccer/list-club-coaches",
		LIST_TEAM_COACHES: "/soccer/list-team-coaches",
		FIND: "/soccer/find-coaches"
	},
	FACILITY: {
		GET_OVERVIEW: "/soccer/get-facility-overview",
		GET_INFO: "/soccer/get-facility-info",
		FACILITY_FIELDS: "/soccer/list-facility-fields",
		GET: "/soccer/find-facilities",
		GET_MINE: "/soccer/list-my-facilities",
		CREATE: "/soccer/create-facility",
		UPDATE: "/soccer/update-facility",
		FIND: "/soccer/find-facilities",
		GET_PHOTOS: "/soccer/list-facility-photos"
	},
	PAYMENT: {
		SUBMIT_PAYMENT: "/payments/submit-payment",
		GET_TRANSACTION_DETAILS: "/payments/transaction-detail"
	},
	FIELDS: {
		CREATE: "/soccer/create-fields",
		UPDATE: "/soccer/update-facility-fields"
	},
	COMPETITION: {
		FIND: "/soccer/find-competitions",
		GET_OVERVIEW: "/soccer/get-competition-overview",
		GET_INFO: "/soccer/get-competition-info",
		GET_TEAM_FILTERS: "/soccer/get-competition-team-filters",
		TEAMS: "/soccer/list-competition-teams",
		PLAYERS: "/soccer/list-competition-players",
		PLAYERS_BY_TEAMS: "/soccer/list-competition-players-by-teams",
		LIST_TEAMS_BY_CLUBS: "/soccer/list-competition-teams-by-clubs",
		CLUBS: "/soccer/list-competition-clubs",
		COMPETITION_MATCHES: "/soccer/list-competition-matches",
		APPLY: "/soccer/apply-to-competition",
		COMPETITION_REFEREES: "/soccer/list-competition-referee-organizations",
		UPDATE_SETTINGS: "/soccer/update-competition",
		GET_COMPETITION_TEAM_STATS: "/soccer/get-competition-team-stats",
		GET_COMPETITION_PLAYER_STATS: "/soccer/get-competition-player-stats",
		GET_COMPETITION_GROUPS: "/soccer/list-competition-groups",
		LIST_FOLLOWED_COMPETITIONS: "/soccer/list-followed-competitions",
		LIST_FIELD_RESERVATIONS: "/soccer/list-competition-field-reservations",
		UPDATE_FIELD_RESERVATIONS: "/soccer/update-competition-field-reservations",
		CREATE_COMPETITION_DIVISIONS: "/soccer/create-competition-divisions",
		UPDATE_COMPETITION_DIVISION: "/soccer/update-competition-division",
		DELETE_COMPETITION_DIVISION: "/soccer/delete-competition-division",
		LIST_COMPETITION_DIVISIONS: "/soccer/list-competition-divisions",
		GET_COMPETITION_DIVISION: "/soccer/get-competition-division",
		LIST_FAVORITE_COMPETITION_DIVISIONS: "/soccer/list-favorite-competition-divisions",
		GET_STRUCTURE: "/soccer/get-competition-structure",
		LIST_SCOUTS: "/soccer/list-competition-scouts",
		UPDATE_SCOUTS: "/soccer/update-competition-scouts",
		INVITE_SCOUTS: "/soccer/invite-competition-scouts",
		UPDATE_TEAMS: "/soccer/update-competition-teams",
		DELETE: "/soccer/delete-competition",
		GENERATE_OPEN_REGISTRATION_PAYMENT_ORDER: "/soccer/generate-competition-open-registration-payment-order",
		APPEND_TO_COMPETITION_OPEN_REGISTRATION: "/soccer/append-to-competition-open-registration",
		GET_AUDIENCES: "/soccer/list-competition-audiences",
		LIST_COMPETITION_FACILITY_FIELDS: "/soccer/list-competition-facility-fields"
	},
	GROUP: {
		FIND_GROUPS: "/soccer/find-groups",
		GROUP_TEAMS: "/soccer/list-group-teams",
		GROUP_MATCHES: "/soccer/list-group-matches",
		GET_GROUP_INFO: "/soccer/get-group-info",
		GROUP_OVERVIEW: "/soccer/get-group-overview",
		CREATE_GROUP: "/soccer/create-group",
		UPDATE_GROUP: "/soccer/update-group",
		GET_GROUP_SCHEDULE: "/soccer/get-group-schedule",
		CREATE_GROUP_SCHEDULE: "/soccer/create-group-schedule",
		UPDATE_GROUP_SCHEDULE: "/soccer/update-group-schedule",
		GET_GROUP_PLAYERS: "/soccer/list-group-players",
		GET_GROUP_STATS: "/soccer/get-group-stats",
		GET_GROUP_TEAM_STATS: "/soccer/get-group-team-stats",
		GET_GROUP_PLAYER_STATS: "/soccer/get-group-player-stats",
		ADD_TEAMS_TO_GROUP: "/soccer/add-teams-to-group",
		LIST_FOLLOWED_GROUPS: "/soccer/list-followed-groups",
		LIST_DIVISION_GROUPS: "/soccer/list-division-groups",
		SUBSTITUTE_DIVISION_TEAM: "/soccer/substitute-division-team"
	},
	INVITATION: {
		LIST_INVITATIONS: "/invitations/list-invitations",
		GET: "/invitations/get-invitation",
		ACCEPT_INVITATION: "/invitations/accept-invitation",
		INVITE_REFEREES: "/soccer/create-referees",
		INVITE_CLUB_FOLLOWERS: "/soccer/invite-club-followers",
		INVITE_LEAGUE_FOLLOWERS: "/soccer/invite-league-followers",
		INVITE_TEAM_FOLLOWERS: "/soccer/invite-team-followers",
		INVITE_COMPETITION_FOLLOWERS: "/soccer/invite-competition-followers",
		INVITE_USER_FOLLOWERS: "/core/invite-user-followers",
		INVITE_GROUP_FOLLOWERS: "/soccer/invite-group-followers"
	},
	TEAM: {
		GET_OVERVIEW: "/soccer/get-team-overview",
		GET_PLAYERS: "/soccer/list-team-players",
		GET_COMPETITIONS: "/soccer/list-team-competitions",
		GET_COMPETITIONS_STATS: "/soccer/get-team-competition-stats",
		GET_PLAYER_COMPETITIONS_STATS: "/soccer/get-team-player-competition-stats",
		UPDATE_TEAM: "/soccer/update-team",
		GET_GROUPS: "/soccer/list-team-groups",
		GET_MATCHES: "/soccer/list-team-matches",
		GET_INFO: "/soccer/get-team-info",
		FIND_TEAMS: "/soccer/find-teams",
		LIST_FOLLOWED_TEAMS: "/soccer/list-followed-teams",
		GET_EVENTS: "/soccer/list-team-events"
	},
	MATCH: {
		OVERVIEW: "/soccer/get-match-overview",
		INFO: "/soccer/get-match-info",
		TEAMS: "/soccer/get-match-teams",
		CREATE_FRIENDLY: "/soccer/create-match",
		CALENDAR_EVENT: "/soccer/create-calendar-event",
		GET_ATTENDANCE: "/core/get-attendance-overview"
	},
	MESSAGING: {
		GET_CONVERSATION: "/messaging/get-conversation",
		GET_CONVERSATIONS: "/messaging/list-conversations",
		SEND_MESSAGE: "/messaging/send-message",
		UPDATE_CONVERSATION: "/messaging/update-conversation",
		DELETE_MESSAGE: "/messaging/remove-message",
		UPDATE_MESSAGE: "/messaging/edit-message"
	},
	EVENT: {
		GET_LIST: "/soccer/list-events"
	},
	PLAYER: {
		GET_INFO: "/soccer/get-player-info",
		GET_OVERVIEW: "/soccer/get-player-overview",
		GET_PLAYER_MATCHES: "/soccer/list-player-matches",
		FIND_PLAYERS: "/soccer/find-players",
		GET_PLAYER_OVERVIEW: "/soccer/list-player-groups",
		GET_PLAYER_STATS: "/soccer/get-player-competition-stats"
	},
	UPLOADS: {
		GET_GOOGLE_SHEET: "/uploads/create-sheet",
		VALIDATE: "/uploads/validate"
	},
	POST: {
		GET: "/posts",
		CREATE: "/posts/create",
		UPDATE: "/posts/update",
		REMOVE: "/posts/remove",
		REMOVE_COMMENT: "/posts/remove-comment",
		GET_POSTS: "/posts/list",
		GET_COMMENTS: "/posts/list-comments",
		REACT: "/posts/react",
		UNREACT: "/posts/remove-reaction",
		GET_REACTIONS: "/posts/list-reactions",
		GET_REACTIONS_STATISTIC: "/reactions/list",
		GET_HASHTAGS: "/hashtags/find",
		CREATE_COMMENT: "/posts/create-comment"
	},
	POLL: {
		VOTE: "/polls/vote"
	},
	GIF: {
		SEARCH: "/gifs/search"
	},
	GUARDIAN: {
		UPDATE_CHILDREN: "/core/update-my-children"
	},
	SOCCER: {
		GET_PAYMENT_ACCOUNT_METRICS: "/soccer/get-payment-account-metrics",
		REFUND_FOR_ORDER: "/soccer/refund-for-order",
		CREATE_PAYMENT_REQUEST: "/soccer/create-payment-request",
		REMOVE_PAYMENT_REQUEST: "/soccer/delete-payment-request",
		LIST_PAYMENT_REQUESTS: "/soccer/list-payment-requests",
		LIST_PAYMENTS_HISTORY: "/soccer/list-payments-history",
		LIST_PAYMENT_REQUEST_ORDERS: "/soccer/list-payment-request-orders",
		LIST_PENDING_ORDERS: "/soccer/list-pending-orders",
		CREATE_STORE: "/soccer/create-store",
		UPDATE_STORE: "/soccer/update-store",
		DELETE_STORE: "/soccer/delete-store"
	},
	STORE: {
		GET_STORE: "/stores/get-store",
		GET_ENTITY_STORES: "/stores/list-entity-stores",
		FIND_STORES: "/stores/find-stores"
	},
	FIREBASE: {
		DEEP_LINK: (key: string) =>
			`${AppConfig.GLOBAL_CONSTANTS.FIREBASE_CONFIG.DYNAMIC_LINKS.FIREBASE_DYNAMIC_LINK_ENDPOINT}?key=${key}`
	},
	NOTIFICATION_UPDATES: {
		CHECK: "/core/check-notification-updates",
		UPDATE_TIME_CHECK: "/core/update-notification-time-check"
	}
};
